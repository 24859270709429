import {CODES_PORTAL_URL, GROUPS_PORTAL_URL} from '../constants/env';
import {getToken, getTokenJingle} from './auth';

export const urlWithToken = (url: string, token: string, expires?: number, token_j?: string) =>
  `${url}?token=${token}&expires=${expires || 604800}&token_j=${token_j || ''}`;

export const getHeaderLinks = () => {
  const token = getToken();
  const token_j = getTokenJingle() || '';
  const links = [
    ...(GROUPS_PORTAL_URL
      ? [
          {
            key: 1,
            label: 'Group Tickets Portal',
            href: urlWithToken(GROUPS_PORTAL_URL, token || '', undefined, token_j),
          },
        ]
      : []),
    ...(CODES_PORTAL_URL
      ? [{key: 2, label: 'Promo Code Portal', href: urlWithToken(CODES_PORTAL_URL, token || '', undefined, token_j)}]
      : []),
  ];
  return links;
};

export const removeEmptyKeys = (obj: any) => {
  const result = {};
  Object.keys(obj).forEach((key) => {
    if (!!obj?.[key] || obj[key] === false) {
      // eslint-disable-next-line
      // @ts-ignore
      result[key] = obj[key];
    }
  });
  return result;
};
