import React from 'react';
import {
  HeaderContainer,
  HeaderLeftSide,
  HeaderRightSide,
  HeaderWrapper,
  HeaderLogoMedium,
  SettingsButton,
  HeaderRightText,
  HeaderButtons,
  LogoutButton,
  HeaderLeftText,
  HeaderDropDown,
  HeaderActiveMenuItem,
  HeaderLink,
  MobileHeaderButtonWrapper,
  MobileButton,
} from './styles';
import {DownOutlined, SettingOutlined} from '@ant-design/icons';
import {Dropdown, Menu} from 'antd';
import {ItemType} from 'antd/es/menu/hooks/useItems';
import {KIT_THEME, themes} from '../theme/theme';
import ENC_logo from '../AppLayout/assets/ENC_Logo_2017_FINAL_BLK.png';
import CC_logo from '../AppLayout/assets/CC_Logo_sm.png';
import Jingle_logo from '../AppLayout/assets/Jingle_Logo_sm.png';
import {ButtonTypes} from '../Button';
import {isMobile} from '../theme/breakpoints';
import logOutIcon from '../../ui-kit/icons/logout.svg';
import backIcon from '../../ui-kit/icons/back.svg';

export type HeaderPropsT = {
  rightText?: string;
  leftText?: string;
  dropDownLinks?: ItemType[];
  onSettingsClick?: () => void;
  goBack?: () => void;
  onLogout?: () => void;
  logoutText?: string;
  logoLink?: string;
  logoTheme?: themes;
};

const Logo = {
  [themes.coke]: CC_logo,
  [themes.enchant]: ENC_logo,
  [themes.lite]: Jingle_logo,
};

export const Header = ({
  onSettingsClick,
  rightText,
  logoutText,
  onLogout,
  leftText,
  dropDownLinks,
  logoLink,
  goBack,
  logoTheme,
}: HeaderPropsT) => {
  return (
    <HeaderContainer>
      <HeaderWrapper>
        {!isMobile && <HeaderLeft leftText={leftText} dropDownLinks={dropDownLinks} />}
        {isMobile && (
          <MobileHeaderButtonWrapper>
            {goBack ? (
              <MobileButton onClick={goBack}>
                <img src={backIcon} />
              </MobileButton>
            ) : (
              <div></div>
            )}
            {onLogout ? (
              <MobileButton>
                <img src={logOutIcon} onClick={onLogout} />
              </MobileButton>
            ) : (
              <div></div>
            )}
          </MobileHeaderButtonWrapper>
        )}
        <HeaderLink to={logoLink || '/'}>
          <HeaderLogoMedium src={Logo[logoTheme || KIT_THEME]} />
        </HeaderLink>
        {!isMobile && (
          <HeaderRightSide>
            {rightText && <HeaderRightText $changeMargin={onSettingsClick ? true : false}>{rightText}</HeaderRightText>}
            <HeaderButtons>
              {onSettingsClick && <SettingsButton onClick={onSettingsClick} icon={<SettingOutlined />} />}
              <LogoutButton onClick={onLogout} variant={ButtonTypes.secondary}>
                {logoutText || 'Log Out'}
              </LogoutButton>
            </HeaderButtons>
          </HeaderRightSide>
        )}
      </HeaderWrapper>
    </HeaderContainer>
  );
};

type HeaderLeftPropsT = {
  leftText?: string;
  dropDownLinks?: ItemType[];
};

const HeaderLeft: React.FC<HeaderLeftPropsT> = ({leftText, dropDownLinks}) => {
  return (
    <HeaderLeftSide>
      {dropDownLinks?.length ? (
        <HeaderDropDown>
          <Dropdown overlay={<Menu items={dropDownLinks} />} trigger={['click']}>
            <HeaderActiveMenuItem>
              <HeaderLeftText>{leftText}</HeaderLeftText>
              <DownOutlined />
            </HeaderActiveMenuItem>
          </Dropdown>
        </HeaderDropDown>
      ) : (
        leftText && <HeaderLeftText>{leftText}</HeaderLeftText>
      )}
    </HeaderLeftSide>
  );
};
