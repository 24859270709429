import React from 'react';
import styled, {css} from 'styled-components';
import {useChangeBrand} from '../../hooks/brand';

export const BrandSwitch = () => {
  const {brand, brandOptions, onChange} = useChangeBrand();
  const onClick = (value: string) => () => onChange(value, true);
  return (
    <Wrapper>
      <Label>Switch Brand:</Label>
      <Brand onClick={onClick(brandOptions[0].value)} $isActive={brand === brandOptions[0].value}>
        {brandOptions[0].label}
      </Brand>
      <Divider>/</Divider>
      <Brand onClick={onClick(brandOptions[1].value)} $isActive={brand === brandOptions[1].value}>
        {brandOptions[1].label}
      </Brand>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: none;
  position: absolute;
  top: 85px;
  right: 80px;
  span {
    font-size: 12px;
  }
`;
const Label = styled.span`
  padding-right: 6px;
`;

const activeCSS = css`
  cursor: auto;
  pointer-events: none;
  font-weight: 800;
`;

const notActiveCSS = css`
  cursor: pointer;
  opacity: 0.6;
  text-decoration: underline;
`;

const Brand = styled.span<{$isActive?: boolean}>`
  ${({$isActive}) => ($isActive ? activeCSS : notActiveCSS)}
`;

const Divider = styled.span`
  padding-left: 6px;
  padding-right: 6px;
`;
