declare global {
  interface Window {
    _env_: {
      REACT_APP_LOGIN_LINK: string | undefined;
      REACT_APP_SERVER_URL: string | undefined;
      REACT_APP_SERVER_JINGLE_URL: string | undefined;
      REACT_APP_GROUPS_PORTAL_URL: string | undefined;
      REACT_APP_CODES_PORTAL_URL: string | undefined;
    };
  }
}

export const SERVER_URL =
  typeof window !== 'undefined' && window._env_ ? window._env_.REACT_APP_SERVER_URL : process.env.REACT_APP_SERVER_URL;

export const SERVER_JINGLE_URL =
  typeof window !== 'undefined' && window._env_
    ? window._env_.REACT_APP_SERVER_JINGLE_URL
    : process.env.REACT_APP_SERVER_JINGLE_URL;

export const LOGIN_EXTERNAL_LINK =
  typeof window !== 'undefined' && window._env_ ? window._env_.REACT_APP_LOGIN_LINK : process.env.REACT_APP_LOGIN_LINK;

export const GROUPS_PORTAL_URL =
  typeof window !== 'undefined' && window._env_
    ? window._env_.REACT_APP_GROUPS_PORTAL_URL
    : process.env.REACT_APP_GROUPS_PORTAL_URL;

export const CODES_PORTAL_URL =
  typeof window !== 'undefined' && window._env_
    ? window._env_.REACT_APP_CODES_PORTAL_URL
    : process.env.REACT_APP_CODES_PORTAL_URL;

export const THEME = undefined;
