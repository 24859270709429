import {useEffect, useState} from 'react';
import {SERVER_JINGLE_URL, SERVER_URL} from '../constants/env';
import Cookies from 'js-cookie';
import {getToken, getTokenJingle} from './auth';
import {themes} from '../ui-kit/theme/theme';

export const brands = {enc: 'ENCHANT & CLASSIC', jingle: 'JINGLE'};

export const useBrand = () => {
  const [brand, setBrand] = useState('');

  useEffect(() => {
    const brand_cookie = getBrandFromCookie();
    if (!brand_cookie) return setBrandCookie(brands.enc, true); // set initial brand if not defined
    setBrand(getBrandFromCookie());
  }, []);

  return {brand, setBrand};
};

export const useChangeBrand = () => {
  const {brand} = useBrand();

  const brandOptions = [
    {
      label: brands.enc,
      value: brands.enc,
    },
    {
      label: brands.jingle,
      value: brands.jingle,
    },
  ];

  return {brand, onChange: setBrandCookie, brandOptions};
};

export const getBrandFromCookie = () => {
  const brand = Cookies.get('brand') || '';
  return brand;
};

export const setBrandCookie = (value?: string, reload?: boolean) => {
  if (getBrandFromCookie() === value) return;
  Cookies.set('brand', value || '', {expires: 365});
  reload && window.location.reload();
};

export const getBrandAPI = () => {
  const brand = getBrandFromCookie();
  if (brand === brands.enc) return SERVER_URL;
  if (brand === brands.jingle) return SERVER_JINGLE_URL;
};

export const getBrandToken = () => {
  const brand = getBrandFromCookie();
  if (brand === brands.enc) return getToken();
  if (brand === brands.jingle) return getTokenJingle();
};

export const getBrandTheme = () => {
  const brand = getBrandFromCookie();
  if (brand === brands.jingle) return themes.lite;
  return themes.enchant;
};
