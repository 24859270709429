import superagent from 'superagent';
import {SERVER_URL} from '../constants/env';
import {getBrandAPI, getBrandToken} from '../hooks/brand';

export const postQuery = (query: string) => {
  const api = getBrandAPI();
  const bearerToken = getBrandToken();
  if (bearerToken) return superagent.post(`${api || SERVER_URL}${query}`).set('Authorization', 'Bearer ' + bearerToken);

  return superagent.post(`${api}${query}`);
};

export const getQuery = (query: string) => {
  const api = getBrandAPI();
  const bearerToken = getBrandToken();
  if (bearerToken) return superagent.get(`${api || SERVER_URL}${query}`).set('Authorization', 'Bearer ' + bearerToken);

  return superagent.get(`${api}${query}`);
};

export const putQuery = (query: string) => {
  const api = getBrandAPI();
  const bearerToken = getBrandToken();
  if (bearerToken) return superagent.put(`${api || SERVER_URL}${query}`).set('Authorization', 'Bearer ' + bearerToken);

  return superagent.put(`${api}${query}`);
};

export const deleteQuery = (query: string) => {
  const api = getBrandAPI();
  const bearerToken = getBrandToken();
  if (bearerToken)
    return superagent.delete(`${api || SERVER_URL}${query}`).set('Authorization', 'Bearer ' + bearerToken);

  return superagent.delete(`${api}${query}`);
};

export type putQueryT = typeof superagent.put;
