import {isMobile} from './../ui-kit/theme/breakpoints';
import {useState, useEffect} from 'react';
import {getErrorResponse, toCreateMessageData, toFindAudience, toUpdateMessageData} from '../helpers/messages';
import {notificationsToTableState, toSelectOptions} from '../helpers/templates';
import {
  createNotification,
  deleteNotification,
  findAudience,
  getListNotifications,
  getNotification,
  getSellers,
  updateNotification,
} from '../queries/notifications';
import {getListNotificationsRes, getNotificationRes} from '../queries/types';
import {SelectOptionT} from '../types/helpers';
import {getListLocationOptionsRes, LocationOptions, MessageCreateFormValues, RecipientT} from '../types/messages';
import {useGetDepartments} from './settings';
import {notification} from 'antd';
import {DashboardTableDataT} from '../types/dashboard';
import {useGetBrands, useGetEventTemplates} from './templates';

export type useCreateMessageT = {
  onCreate?: (data: MessageCreateFormValues) => Promise<boolean>;
  onUpdate?: (data: MessageCreateFormValues) => Promise<boolean>;
  onFindAudience?: (data: MessageCreateFormValues) => Promise<boolean | undefined>;
  success?: {
    status?: 'completed' | 'failed';
    total?: number;
  };
  error?: string;
  resetResult?: () => void;
  options: {departments: SelectOptionT[]; brand: SelectOptionT[]; ticketTypeOptions: SelectOptionT[]};
  sellerOptions: LocationOptions[];
  guests: number | null;
  numTickets: number | null;
  loading: boolean;
  setGuests: (v: number | null) => void;
  setTickets: (v: number | null) => void;
  refetchSellers: (brand?: string) => void;
  recipients?: RecipientT[];
};

export const useGetListNotificationsTable = () => {
  const [currentTab, setCurrentTab] = useState<string>('all');

  //pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [notificationLimit, setNotificationLimit] = useState(10);

  const [loading, setLoading] = useState(false);

  const [notifications, setNotifications] = useState<getListNotificationsRes>([]);
  const [nonFilteredCodes, setNonFilteredCodes] = useState<getListNotificationsRes>([]);
  const [filteredNotifications, setFilteredNotifications] = useState<DashboardTableDataT[]>([]);
  const [nonFilteredNotifications, setNonFilteredNotifications] = useState<DashboardTableDataT[]>([]);

  const [sellerOptions, setSellerOptions] = useState<getListLocationOptionsRes>([]);
  const [isSearching, setIsSearching] = useState(false);

  const fetch = () => {
    try {
      setLoading(true);
      Promise.all([getListNotifications(), getSellers()]).then(([resNotifications, resOptions]) => {
        const notifications = (resNotifications?.body).sort((a: any, b: any) => Number(b?.id) - Number(a?.id));
        setNotifications(notifications);
        setNonFilteredCodes(notifications);
        setSellerOptions(resOptions?.body as getListLocationOptionsRes);
        // setFilteredNotifications(
        //   notificationsToTableState(notifications, resOptions?.body).slice(0, notificationLimit),
        // );
        setNonFilteredNotifications(notificationsToTableState(notifications, resOptions?.body));
        setFilteredNotifications(notificationsToTableState(notifications, resOptions?.body));
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const changeTab = (val: string) => {
    setCurrentTab(val);

    if (val === 'all') return setFilteredNotifications(nonFilteredNotifications);
    if (val === 'lite')
      return setFilteredNotifications(
        nonFilteredNotifications.filter((el) => el?.brand?.includes('lite') || el?.brand?.includes('jingle')),
      );
    return setFilteredNotifications(nonFilteredNotifications.filter((el) => el?.brand === val));
  };

  const searchNotifications = (v: string) => {
    if (notifications) {
      if (v === '') {
        setIsSearching(false);
        setNotifications(nonFilteredCodes);
        return;
      }
      setIsSearching(true);
      if (nonFilteredCodes.some((el) => el?.name?.toLowerCase().includes(v.toLowerCase()))) {
        setNotifications(nonFilteredCodes.filter((el) => el?.name?.toLowerCase().includes(v.toLowerCase())));
      } else {
        setNotifications([]);
      }
    }
  };

  const changePage = (val: number) => {
    const noti = notificationsToTableState(notifications, sellerOptions).slice(
      (val - 1) * notificationLimit,
      val * notificationLimit,
    );
    setFilteredNotifications((prev) => (!isMobile ? noti : [...prev, ...noti]));
    setPageNumber(val);
  };

  const changeLimit = (_: number, val: number) => setNotificationLimit(val);

  useEffect(() => {
    fetch();
  }, []);

  return {
    notifications: filteredNotifications,
    searchNotifications,
    refetch: fetch,
    loading,
    isSearching,
    currentTab,
    changeTab,
    pagination: {
      hasNextPage: (nonFilteredCodes.length || 0) / notificationLimit > pageNumber,
      pageNumber,
      notificationLimit,
      changePage,
      changeLimit,
      total: nonFilteredCodes.length || 0,
    },
    data: nonFilteredCodes,
  };
};

export const useCreateNotification = (onSuccess?: () => void): useCreateMessageT => {
  const [success, setSuccess] = useState({});
  const [error, setError] = useState('');
  const [guests, setGuests] = useState<null | number>(null);
  const [recipients, setRecipients] = useState<RecipientT[] | undefined>();
  const [numTickets, setNumTickets] = useState<null | number>(null);
  const [sellerOptions, setSellerOptions] = useState<getListLocationOptionsRes>([]);
  const [loading, setLoading] = useState(false);
  const {departments} = useGetDepartments();
  const {brandOptions} = useGetBrands();
  const {ticketTypeOptions} = useGetEventTemplates();

  const resetResult = () => {
    setError('');
    setSuccess({});
  };

  const getError = (e: any) => {
    const error = JSON.parse(JSON.stringify(e));
    return error?.response?.text && JSON.parse(error?.response?.text)?.message;
  };

  const onCreate = async (data: MessageCreateFormValues) => {
    const body = toCreateMessageData(data);
    const id = sellerOptions?.filter((opt: LocationOptions) => opt.id === data?.guestsInLocation)[0].id;
    const createBody = {...body, eventSellerId: id};
    try {
      setLoading(true);
      await createNotification({body: createBody});
      onSuccess?.();
      setSuccess({status: 'completed', total: 1});
      return true;
    } catch (e) {
      console.log(e);
      const errorMessage = getError(e);
      setError(errorMessage);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const onFindAudience = async (data: MessageCreateFormValues) => {
    const id = sellerOptions?.filter((opt: LocationOptions) => opt.id === data?.guestsInLocation)[0].id;
    const audBody = toFindAudience(data);

    const bodyWithId = {...audBody, eventSellerId: id};
    try {
      setLoading(true);
      await findAudience({body: bodyWithId}).then((res) => {
        if (!res.body.length) {
          setNumTickets(0);
          return setGuests(0);
        }
        setRecipients(res.body);
        setGuests(res.body.length);
        const totalTickets = res.body.reduce((acc: any, el: any) => acc + parseInt(el.totalTickets), 0);
        setNumTickets(totalTickets);
      });
    } catch (e) {
      console.log(e);
      const errorMessage = getError(e);
      setError(errorMessage);
      return false;
    } finally {
      setLoading(false);
    }
  };
  const fetch = (brand?: string) => {
    setLoading(true);
    getSellers(brand).then((res) => {
      setSellerOptions(res?.body as getListLocationOptionsRes);
    });
    setLoading(false);
  };

  useEffect(() => {
    fetch();
  }, []);

  return {
    onCreate,
    onFindAudience,
    success,
    error,
    resetResult,
    options: {
      departments: toSelectOptions(departments),
      brand: brandOptions || [],
      ticketTypeOptions: ticketTypeOptions || [],
    },
    sellerOptions,
    loading,
    guests,
    numTickets,
    setGuests,
    setTickets: setNumTickets,
    refetchSellers: fetch,
    recipients,
  };
};

export const useGetNotification = (id?: string, skip?: boolean) => {
  const [notification, setNotification] = useState<getNotificationRes>();
  const [loading, setLoading] = useState(false);

  const fetch = async () => {
    if (!id || skip) return;
    setLoading(true);
    await getNotification({notificationId: id})
      .then((res) => {
        const result = res?.body as getNotificationRes;
        setNotification({...result, orderStatus: result?.orderStatus?.length === 2 ? 'all' : result?.orderStatus?.[0]});
        setLoading(false);
      })
      .catch(() => setLoading(false));
    setLoading(false);
  };

  useEffect(() => {
    fetch();
  }, [id, skip]);

  return {notification, loading, refetch: fetch};
};

export const useUpdateNotification = (id?: string, onSuccess?: () => void): useCreateMessageT => {
  const [success, setSuccess] = useState({});
  const [error, setError] = useState('');
  const [sellerOptions, setSellerOptions] = useState<getListLocationOptionsRes>([]);
  const [loading, setLoading] = useState(false);
  const [guests, setGuests] = useState<null | number>(null);
  const [recipients, setRecipients] = useState<RecipientT[] | undefined>();
  const [numTickets, setNumTickets] = useState<null | number>(null);
  const {departments} = useGetDepartments();
  const {brandOptions} = useGetBrands();
  const {ticketTypeOptions} = useGetEventTemplates();

  const getError = (e: any) => {
    const error = JSON.parse(JSON.stringify(e));
    return error?.response?.text && JSON.parse(error?.response?.text)?.message;
  };

  const resetResult = () => {
    setError('');
    setSuccess({});
  };

  const onUpdate = async (data: MessageCreateFormValues) => {
    try {
      if (!id) return false;
      setLoading(true);
      const bodyFields = toUpdateMessageData(data);
      const sellerId = sellerOptions?.find((opt: LocationOptions) => opt.id === data?.guestsInLocation)?.id || '';
      const bodyWithId = {...bodyFields, eventSellerId: sellerId};
      await updateNotification({notificationId: id, body: bodyWithId});
      onSuccess?.();
      setSuccess({status: 'completed'});
      return true;
    } catch (e) {
      setSuccess({});
      console.log(e);
      const errorMessage = getError(e);
      setError(errorMessage);
      return false;
    } finally {
      setLoading(false);
    }
  };
  const onFindAudience = async (data: MessageCreateFormValues) => {
    const id = sellerOptions?.find((opt: LocationOptions) => opt.id === data?.guestsInLocation)?.id || '';
    const audBody = toFindAudience(data);

    const bodyWithId = {...audBody, eventSellerId: id};
    try {
      setLoading(true);
      await findAudience({body: bodyWithId}).then((res) => {
        if (!res.body.length) {
          setNumTickets(0);
          return setGuests(0);
        }
        setRecipients(res.body);
        setGuests(res.body.length);
        const totalTickets = res.body.reduce((acc: any, el: any) => acc + parseInt(el.totalTickets), 0);
        setNumTickets(totalTickets);
      });
    } catch (e) {
      console.log(e);
      const errorMessage = getError(e);
      setError(errorMessage);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const fetch = () => {
    setLoading(true);
    getSellers().then((res) => {
      setSellerOptions(res?.body as getListLocationOptionsRes);
    });
    setLoading(false);
  };

  useEffect(() => {
    fetch();
  }, []);

  return {
    onCreate: onUpdate,
    onFindAudience,
    success,
    error,
    resetResult,
    loading,
    options: {
      departments: toSelectOptions(departments),
      brand: brandOptions || [],
      ticketTypeOptions: ticketTypeOptions || [],
    },
    sellerOptions,
    guests,
    setGuests,
    numTickets,
    setTickets: setNumTickets,
    refetchSellers: fetch,
    recipients,
  };
};

export const openNotifError = (message?: string) => {
  notification['error']({
    message: 'Error',
    description: message,
  });
};
export const useDeleteNotification = (onSuccess?: () => void) => {
  const onDelete = async (notificationId: string) => {
    try {
      const res = await deleteNotification({notificationId});
      if (res) onSuccess?.();
      return true;
    } catch (e) {
      const err = getErrorResponse(e);
      err?.message && openNotifError(err.message);
      return false;
    }
  };
  return {onDelete};
};

// export type getGuestsNumberReq = {
//   eventSellerId: string;
//   eventTemplateName: string;
//   eventStartDate: string;
//   eventEndDate: string;
// };

// export const getGuestsNumber = () => {
//   const [number, setNumber] = useState(0);

//   const fetch = async (body?: getGuestsNumberReq) => {
//     if (!body) return;
//     const res = await findAudience({body});
//     console.log(res);
//     setNumber(res?.body?.length);
//   };
//   return {getNumberGuests: fetch, number};
// };
