import {useState, useEffect} from 'react';
import {templatesToTableState, toCreateTemplateData, toSelectOptions, toUpdateTemplateData} from '../helpers/templates';
import {
  createTemplate,
  deleteTemplate,
  findAudience,
  getBrands,
  getEventTemplates,
  getListTemplates,
  getNotification,
  getNotificationLogs,
  getNotificationLogsCSV,
  getSellers,
  getTemplate,
  updateTemplate,
} from '../queries/notifications';
import {getListTemplatesRes, getTemplateRes} from '../queries/types';
import {SelectOptionT} from '../types/helpers';
import {LocationOptions, LogT, NotificationDetailedT, RecipientT, TemplateCreateFormValues} from '../types/messages';
import {useGetDepartments} from './settings';
import {useParams} from 'react-router-dom';
import {themes} from '../ui-kit/theme/theme';
import {getBrandTheme} from './brand';

export type useCreateTemplateT = {
  onCreate?: (data: TemplateCreateFormValues) => Promise<boolean>;
  onUpdate?: (data: TemplateCreateFormValues) => Promise<boolean>;
  success?: {
    status?: 'completed' | 'failed';
    total?: number;
  };
  error?: string;
  resetResult?: () => void;
  options: {departments: SelectOptionT[]; brand: SelectOptionT[]};
};

export const useGetListTemplatesTable = () => {
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState<getListTemplatesRes>([]);
  const fetch = () => {
    setLoading(true);
    getListTemplates().then((res) => {
      setTemplates(res?.body as getListTemplatesRes);
      setLoading(false);
    });
  };
  useEffect(() => {
    fetch();
  }, []);

  return {templates: templatesToTableState(templates), refetch: fetch, loading};
};

export const useCreateTemplate = (onSuccess?: () => void): useCreateTemplateT => {
  const [success, setSuccess] = useState({});
  const [error, setError] = useState('');
  const resetResult = () => {
    setError('');
    setSuccess({});
  };
  const {departments} = useGetDepartments();
  const {brandOptions} = useGetBrands();
  const onCreate = async (data: TemplateCreateFormValues) => {
    const body = toCreateTemplateData(data);
    try {
      console.log(body);
      await createTemplate({body});
      onSuccess?.();
      setSuccess({status: 'completed', total: 1});
      return true;
    } catch (e) {
      setError('Create Error!');
      console.log(e);
      return false;
    }
  };

  return {
    onCreate,
    success,
    error,
    resetResult,
    options: {departments: toSelectOptions(departments), brand: brandOptions || []},
  };
};

export const useGetTemplate = (id?: string, skip?: boolean) => {
  const [template, setTemplate] = useState<getTemplateRes>();
  const [loading, setLoading] = useState(false);

  const fetch = async () => {
    if (!id || skip) return;
    setLoading(true);
    await getTemplate({templateId: id})
      .then((res) => {
        setTemplate(res?.body as getTemplateRes);
        setLoading(false);
      })
      .catch(() => setLoading(false));
    setLoading(false);
  };

  const get = async (id?: string) => {
    if (!id) return;
    await getTemplate({templateId: id})
      .then((res) => {
        setTemplate(res?.body as getTemplateRes);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    fetch();
  }, [id, skip]);

  return {template, loading, refetch: fetch, getOne: get};
};

export const useUpdateTemplate = (id?: string, onSuccess?: () => void): useCreateTemplateT => {
  const [success, setSuccess] = useState({});
  const [error, setError] = useState('');
  const resetResult = () => {
    setError('');
    setSuccess({});
  };
  const {departments} = useGetDepartments();
  const {brandOptions} = useGetBrands();
  const onUpdate = async (data: TemplateCreateFormValues) => {
    try {
      if (!id) return false;
      const bodyFields = toUpdateTemplateData(data);
      await updateTemplate({templateId: id, body: bodyFields});
      onSuccess?.();
      setSuccess({status: 'completed'});
      return true;
    } catch (e) {
      setError('Update Error!');
      setSuccess({});
      console.log(e);
      return false;
    }
  };

  return {
    onCreate: onUpdate,
    success,
    error,
    resetResult,
    options: {departments: toSelectOptions(departments), brand: brandOptions || []},
  };
};

export const useDeleteTemplate = (onSuccess?: () => void) => {
  const onDelete = async (templateId: string) => {
    try {
      const res = await deleteTemplate({templateId});
      if (res) onSuccess?.();
    } catch (e) {}
  };
  return {onDelete};
};

export const useGetBrands = () => {
  const [brands, setBrands] = useState<{key: string; name: string}[]>();

  const fetch = async () => {
    try {
      const res = await getBrands();
      const theme = getBrandTheme();

      const list = res?.body?.filter((el: any) => {
        if (theme === themes.lite) return el?.name?.toLowerCase().includes('jingle');
        return !el?.name?.toLowerCase().includes('jingle');
      });
      setBrands(list);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetch();
  }, []);
  const brandOptions = brands?.map((el) => ({label: el.name, value: el.key}));
  return {brands, brandOptions};
};

export const useLoadNotification = () => {
  const id = useParams<{id: string}>()?.id;
  const [notification, setNotification] = useState<NotificationDetailedT>();
  const [loading, setLoading] = useState(true);
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [locations, setLocations] = useState<LocationOptions[]>([]);
  const [audience, setAudience] = useState<RecipientT[]>([]);
  const [logs, setLogs] = useState<LogT[]>();

  const fetchNotification = async () => {
    if (!id) return setLoading(false);
    try {
      setLoading(true);
      const res = await getNotification({notificationId: id});
      const notification = res?.body as NotificationDetailedT;
      const locationRes = await getSellers();
      const logs = await getNotificationLogs(id);
      const audience = await findAudience({
        body: {
          brand: notification?.brand || '',
          eventTemplateName: notification?.eventTemplateName,
          eventStartDate: notification?.eventStartDate || '',
          eventEndDate: notification?.eventEndDate || '',
          eventSellerId: notification?.eventSellerId?.[0] || '',
        },
      });
      setLogs(logs?.body);
      setAudience(audience?.body);
      setNotification(res?.body);
      setLocations(locationRes?.body);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  const downloadCSV = async () => {
    try {
      if (!id) return;
      setLoadingCSV(true);
      const res = await getNotificationLogsCSV(id);
      const tempUrl = URL.createObjectURL(res.body);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = tempUrl;
      a.download = res.headers?.filename || `Logs_${id}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(tempUrl);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoadingCSV(false);
    }
  };

  useEffect(() => {
    fetchNotification();
  }, []);

  return {notification, loading, locations, audience, logs, loadingCSV, downloadCSV};
};

export const useFilterLogs = ({logs}: {logs?: LogT[]}) => {
  const [filter, setFilter] = useState<{type?: string; status?: string}>({});
  const typeOptions = [
    {
      value: 'all',
      label: 'All',
    },
    {
      value: 'email',
      label: 'Email',
    },
    {
      value: 'sms',
      label: 'SMS',
    },
  ];
  const statusOptions = [
    {
      value: 'all',
      label: 'All',
    },
    {
      value: 'delivered',
      label: 'Delivered',
    },
    {
      value: 'failed',
      label: 'Failed',
    },
  ];
  const onChange = (name: string) => (value?: any) => setFilter((prev) => ({...prev, [name]: value}));
  const filteredLogs = logs?.filter((el) => {
    let pass = true;
    if (filter.type && filter.type !== 'all') {
      if (el.notificationMode !== filter.type) return (pass = false);
    }
    if (filter.status && filter.status !== 'all') {
      if (el.outboundStatus !== filter.status) return (pass = false);
    }
    return pass;
  });

  return {typeOptions, statusOptions, filteredLogs, onChange, filter};
};

export const useGetEventTemplates = () => {
  const [eventTemplates, setEventTemplates] = useState<string[]>();

  const fetch = async () => {
    try {
      const res = await getEventTemplates();
      setEventTemplates(res?.body);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetch();
  }, []);
  const ticketTypeOptions = eventTemplates?.map((el) => ({label: el, value: el}));
  return {eventTemplates, ticketTypeOptions};
};
