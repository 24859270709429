import React, {useEffect, useState} from 'react';
import {
  CreateNewMessageCodeForm,
  CodeFormItem,
  FormButton,
  GroupTitle,
  StyledSelect,
  MessageFormWrapper,
  LeftSide,
  RightSide,
  SettingsTitle,
  DoubleSelectWrapper,
  ErrorLine,
  ErrorText,
  SuccessLine,
  SuccessText,
  CustomDatePicker,
  TextLimitTip,
  TicketTypeTip,
  PageWrapper,
  TitleLine,
  ModalDescr,
  StyledSpan,
  LoadingWrapper,
} from './styles';
import {LinkBack} from '../../../../ui-kit/Button/LinkBack';
import Input, {TextArea} from '../../../../ui-kit/Input';
import {Form as FormAntd, Modal} from 'antd';
import {FormTips, formTitles, getFormButtons, setValuesOnBlurName} from '../../common';
import {CustomCheckbox} from '../../../../ui-kit/Checkbox';
import {Divider} from 'antd';
import {FormMode, MessageCreateFields} from '../../../../types/messages';
import {SelectWithCheckbox} from '../../../../ui-kit/Select';
import {StyledTimePicker} from '../../../../ui-kit/TimePicker/styles';
import {WarningOutlined} from '@ant-design/icons';
import {ModalLvl} from '../../../../types/settings';
import {route} from '../../../../constants/routes';
import {Loader} from '../../../../ui-kit/Loader';
import moment from 'moment';
import {
  defaultSubject,
  havingTicketsOptions as havingTicketsOptionsHardcode,
  messageTypeOptions,
  messageTypes,
  notificationModeOptions,
  ticketStatusOptions,
} from '../../../../constants/dashboard';
import {MessageFormProps, TextLImitTypeT} from '../../types';
import {CheckboxLabel, ItemLabel} from '../../../../ui-kit/Labels';
import {InstructionForVariables} from '../../../../containers/Template/InstructionForVariables';
import styled from 'styled-components';
import {PreviewMessage} from '../../../../containers/Message/Preview';

export const CreateMessageDesktop: React.FC<MessageFormProps> = ({
  mode,
  formData,
  templates,
  initial,
  selectedTemplate,
  getTemplate,
  loading,
  goDashboard,
}) => {
  const {
    onCreate,
    onFindAudience,
    error,
    success,
    resetResult,
    sellerOptions,
    guests,
    setGuests,
    numTickets,
    setTickets,
    options,
    recipients,
  } = formData;
  const havingTicketsOptions = (
    options.ticketTypeOptions?.length ? options.ticketTypeOptions : havingTicketsOptionsHardcode
  ) as {label: string; value: string}[];
  const [form] = FormAntd.useForm();

  const [flags, setFlags] = useState<{
    isEmailMode: boolean;
    isExclude: boolean;
    isInstant: boolean;
    isCreateDisabled: boolean;
    dateWarning: boolean;
  }>({
    isEmailMode: initial?.notificationMode?.includes('email') ? true : false,
    isExclude: initial?.eventTemplateName?.exclude.length ? true : false,
    isInstant: false,
    isCreateDisabled: mode === 'create',
    dateWarning: false,
  });

  const [formValues, setFormValues] = useState<any>(); //values for preview message
  const [nonValidScheduled, setNonValid] = useState<boolean>();
  const [view, setView] = useState<'form' | 'preview'>('form');
  const showForm = () => setView('form');
  const showPreview = () => setView('preview');
  const [text, setText] = useState<TextLImitTypeT>({content: '', limit: 1000});
  const [delModalLvl, setDelModalLvl] = useState<ModalLvl>(ModalLvl.closed);
  // const [previewModalLvl, setPreviewModalLvl] = useState<ModalLvl>(ModalLvl.closed);
  const [excludeOptions, setExcludeOptions] = useState<typeof havingTicketsOptions>([]);

  const title = formTitles[mode];

  const locationOptions = sellerOptions?.map((option) => ({
    id: option.id,
    label: `${option.city} (${option.venueName})`,
    value: option.id,
  }));
  const isDisabled = initial?.notificationType === messageTypes.instant;

  const button = getFormButtons(flags.isInstant)[mode];

  const toggleExclude = () => {
    if (!form.getFieldValue(MessageCreateFields.guestsHavingTickets)) return;
    form.setFieldsValue({[MessageCreateFields.excludeGuestsHavingTickets]: []});
    setFlags((prev) => ({...prev, isExclude: !prev.isExclude}));
  };

  const onChangeNotifMode = (value: string[]) => {
    setText((prev) => (value.includes('sms') ? {...prev, limit: 1000} : {...prev, limit: 1000}));
    setFlags((prev) => ({...prev, isEmailMode: value.includes('email') ? true : false}));
    form.setFieldsValue({[MessageCreateFields.notificationMode]: value});
  };

  const setDelPopup = (lvl: ModalLvl) => setDelModalLvl(lvl);

  const templateOptions = templates.map((el) => ({label: el.messageTitle, value: `${el.messageTitle}`}));

  const checkCreateDisabled = () => {
    const values = form.getFieldsValue();

    const scheduleCondition =
      values[MessageCreateFields.notificationType] === messageTypes.scheduled
        ? !values[MessageCreateFields.scheduledDate] || !values[MessageCreateFields.scheduledTime]
        : false;

    const disabled =
      scheduleCondition ||
      isDisabled ||
      loading ||
      !values[MessageCreateFields.guestsHavingTickets] ||
      !values[MessageCreateFields.visitingFromTime] ||
      !values[MessageCreateFields.visitingUntilTime] ||
      !values[MessageCreateFields.guestsInLocation] ||
      !values[MessageCreateFields.message];
    setFlags((prev) => ({...prev, isCreateDisabled: disabled}));
  };

  const inputsFocusing = (e: any) => {
    if (e.type === 'blur' && e.target.value && e.target.id && setValuesOnBlurName.includes(e.target.id)) {
      form.setFieldsValue({[e.target.id]: moment.utc(e.target.value, 'h:mm a')});
    }
    if (e?.target?.id === MessageCreateFields.message) {
      setText((prev) => ({...prev, content: e?.target?.value || ''}));
      form.setFieldsValue({[MessageCreateFields.message]: e?.target?.value});
    }
    checkCreateDisabled();
  };

  const handleChangeForm = () => {
    resetResult?.();
    checkCreateDisabled();
  };

  const onFinish = async (values: any) => {
    if (values.visitingFromDate > values.visitingUntilDate) {
      setFlags((prev) => ({...prev, dateWarning: true}));
      return;
    }
    setFlags((prev) => ({...prev, dateWarning: false}));
    await onFindAudience?.({...values});
    onPreview();
  };

  const onGuestsHavingTicketsChange = (value: any) => {
    const id = templates?.find((t) => t?.messageTitle === value)?.key;
    getTemplate?.(String(id || ''));
    form.setFieldsValue({[MessageCreateFields.excludeGuestsHavingTickets]: []});
    setExcludeOptions(havingTicketsOptions.filter((item) => item.value !== value));
  };

  const onTemplateNameChange = (value: any) => {
    const id = templates?.find((t) => t?.messageTitle === value)?.key;
    getTemplate?.(String(id || ''));
  };

  const onSelectChange = (v: any) => setFlags((prev) => ({...prev, isInstant: v === 'instant' ? true : false}));

  const onSelectDynamicTemplate = (value: string) => () => {
    const current = form.getFieldValue(MessageCreateFields.message) || '';
    setText((prev) => ({...prev, content: current + value}));
    form.setFieldsValue({[MessageCreateFields.message]: current + value});
    checkCreateDisabled();
  };

  const onBrandChange = (value: any) => {
    formData.refetchSellers(value);
    form.resetFields([MessageCreateFields.guestsInLocation]);
  };
  useEffect(() => {
    if (selectedTemplate?.id) {
      setText((prev) => ({...prev, content: selectedTemplate?.message || ''}));
      form.setFieldsValue({[MessageCreateFields.message]: selectedTemplate?.message});
      checkCreateDisabled();
    }
  }, [selectedTemplate?.id]);

  useEffect(() => {
    if (initial) {
      initial?.notificationMode && onChangeNotifMode(initial?.notificationMode);
      if (initial?.notificationType === messageTypes.instant) {
        setFlags((prev) => ({...prev, isInstant: true}));
      }
      if (initial?.eventTemplateName?.exclude.length) {
        setFlags((prev) => ({...prev, isExclude: true}));
      }
      if (initial?.message?.length) {
        setText((prev) => ({...prev, content: initial?.message || ''}));
      }
      form.setFieldsValue({[MessageCreateFields.name]: initial?.name});
      form.setFieldsValue({[MessageCreateFields.flagReason]: initial?.flagReason});
      form.setFieldsValue({[MessageCreateFields.notificationType]: initial?.notificationType});
      form.setFieldsValue({
        [MessageCreateFields.scheduledDate]: initial?.scheduledTime ? moment.utc(initial?.scheduledTime) : undefined,
      });
      form.setFieldsValue({
        [MessageCreateFields.scheduledTime]: initial?.scheduledTime ? moment.utc(initial?.scheduledTime) : undefined,
      });
      form.setFieldsValue({[MessageCreateFields.guestsInLocation]: initial?.eventSellerId});
      form.setFieldsValue({[MessageCreateFields.brand]: initial?.brand});
      form.setFieldsValue({[MessageCreateFields.guestsHavingTickets]: initial?.eventTemplateName?.include});
      form.setFieldsValue({[MessageCreateFields.excludeGuestsHavingTickets]: initial?.eventTemplateName?.exclude});
      form.setFieldsValue({
        [MessageCreateFields.orderStatus]: mode === 'create' ? ticketStatusOptions[0].value : initial?.orderStatus,
      });
      form.setFieldsValue({
        [MessageCreateFields.visitingFromDate]: initial?.eventStartDate
          ? moment.utc(initial?.eventStartDate)
          : undefined,
      });
      form.setFieldsValue({
        [MessageCreateFields.visitingFromTime]: initial?.eventStartDate
          ? moment.utc(initial?.eventStartDate)
          : undefined,
      });
      form.setFieldsValue({
        [MessageCreateFields.visitingUntilDate]: initial?.eventEndDate ? moment.utc(initial?.eventEndDate) : undefined,
      });
      form.setFieldsValue({
        [MessageCreateFields.visitingUntilTime]: initial?.eventEndDate ? moment.utc(initial?.eventEndDate) : undefined,
      });
      form.setFieldsValue({[MessageCreateFields.allowDateChange]: initial?.allowDateChange});
      form.setFieldsValue({[MessageCreateFields.allowRefund]: initial?.allowRefund});
      form.setFieldsValue({[MessageCreateFields.skipMessaging]: initial?.skipMessage});
      form.setFieldsValue({[MessageCreateFields.message]: initial?.message});
      form.setFieldsValue({[MessageCreateFields.subject]: initial?.subject});
    }
  }, [JSON.stringify(initial)]);

  useEffect(() => {
    if (delModalLvl === ModalLvl.confirm) {
      Modal.confirm({
        title: `Are you sure you want to leave Create new Message?`,
        content: <ModalDescr>You cannot undo this action.</ModalDescr>,
        okText: 'Yes',
        cancelText: 'No',
        autoFocusButton: null,
        icon: <WarningOutlined />,
        onOk: () => setDelPopup(ModalLvl.success),
        onCancel: () => setDelPopup(ModalLvl.closed),
      });
    }
    if (delModalLvl === ModalLvl.success) {
      Modal.success({
        title: ``,
        content: <ModalDescr></ModalDescr>,
        okText: 'Done',
        onOk: () => setDelPopup(ModalLvl.closed),
      });
    }
  }, [delModalLvl]);

  useEffect(() => {
    if (success?.status === 'completed') goDashboard();
  }, [error, success]);

  const previewOnCreate = () => {
    const values = form.getFieldsValue();
    onCreate?.({...values});
  };
  const previewOnCancel = () => {
    setGuests(null);
    setTickets(null);
    showForm();
  };

  const onPreview = () => {
    const values = form.getFieldsValue();
    const today = moment.utc(new Date());
    const hours = values?.scheduledTime?.hours();
    const minutes = values?.scheduledTime?.minutes();
    const _s = values?.scheduledDate;
    if (_s) {
      _s.set('hours', hours);
      _s.set('minutes', minutes);
    }
    const diff_minutes = today.diff(_s, 'minutes');
    const nonValidScheduled = values?.scheduledDate ? diff_minutes >= 0 : false;

    setNonValid(nonValidScheduled);
    setFormValues(form.getFieldsValue());
    showPreview();
  };

  return (
    <PageWrapper>
      {loading && (
        <LoadingWrapper>
          <Loader />
        </LoadingWrapper>
      )}
      {view === 'form' && (
        <TitleLine>
          <LinkBack to={route.dashboard.path}>Back to Dashboard</LinkBack>
        </TitleLine>
      )}
      {view === 'preview' && (
        <TitleLine>
          <LinkBack to="#" onClick={showForm}>
            Edit Message
          </LinkBack>
        </TitleLine>
      )}
      {view === 'preview' && (
        <PreviewMessage
          values={formValues}
          guests={guests}
          numTickets={numTickets}
          locations={locationOptions}
          nonValidScheduled={nonValidScheduled}
          onBack={previewOnCancel}
          onSubmit={previewOnCreate}
          recipients={recipients}
        />
      )}
      <CreateFormWrapper $hidden={view !== 'form'}>
        <CreateNewMessageCodeForm layout={'vertical'} form={form} onChange={handleChangeForm} onFinish={onFinish}>
          <GroupTitle>{title}</GroupTitle>
          <Divider />
          <MessageFormWrapper>
            <LeftSide>
              <SettingsTitle>Schedule Settings</SettingsTitle>
              <CodeFormItem
                label={<ItemLabel label="Notification Name*" title={FormTips.notificationName} />}
                rules={[{required: true, message: 'Notification Name is required'}]}
                name={[MessageCreateFields.name]}>
                <Input placeholder="Enter name" disabled={isDisabled} />
              </CodeFormItem>
              <CodeFormItem
                label={<ItemLabel label="Delivery Type*" title={FormTips.messageType} />}
                name={[MessageCreateFields.notificationType]}
                rules={[{required: true, message: 'Message Type is required'}]}>
                <StyledSelect
                  placeholder={'Choose type'}
                  onChange={onSelectChange}
                  options={messageTypeOptions}
                  disabled={isDisabled}
                  onFocus={inputsFocusing}
                  onBlur={inputsFocusing}
                />
              </CodeFormItem>
              {!flags.isInstant && (
                <DoubleSelectWrapper>
                  <CodeFormItem
                    label={<ItemLabel label="Date*" title={FormTips.date} />}
                    name={[MessageCreateFields.scheduledDate]}
                    rules={[{required: true, message: 'Date is required'}]}>
                    <CustomDatePicker onChange={checkCreateDisabled} />
                  </CodeFormItem>
                  <CodeFormItem
                    label={<ItemLabel label="Time*" title={FormTips.time} />}
                    name={[MessageCreateFields.scheduledTime]}
                    rules={[{required: true, message: 'Time is required'}]}>
                    <StyledTimePicker
                      onFocus={inputsFocusing}
                      onBlur={inputsFocusing}
                      use12Hours
                      format="h:mm a"
                      placeholder="Choose time"
                      onChange={checkCreateDisabled}
                    />
                  </CodeFormItem>
                </DoubleSelectWrapper>
              )}
              <CodeFormItem
                label={<ItemLabel label="Notification Mode*" title={FormTips.notification} />}
                name={[MessageCreateFields.notificationMode]}
                required
                rules={[{required: true, message: 'Notification Mode is required'}]}>
                <SelectWithCheckbox
                  onChange={onChangeNotifMode}
                  options={notificationModeOptions}
                  placeholder="Select Notification Mode"
                  disabled={isDisabled}
                />
              </CodeFormItem>
              <Divider />
              <SettingsTitle>Audience Settings</SettingsTitle>
              <CodeFormItem
                label={<ItemLabel label="Brand*" />}
                rules={[{required: true, message: 'Brand is required'}]}
                name={[MessageCreateFields.brand]}>
                <StyledSelect
                  placeholder={'Brand'}
                  options={options.brand}
                  disabled={isDisabled}
                  onFocus={inputsFocusing}
                  onBlur={inputsFocusing}
                  onChange={onBrandChange}
                />
              </CodeFormItem>
              <CodeFormItem
                label={<ItemLabel label="Location*" title={FormTips.guestLocation} />}
                rules={[{required: true, message: 'Guests in location is required'}]}
                name={[MessageCreateFields.guestsInLocation]}>
                <StyledSelect
                  placeholder={'Choose Location'}
                  options={locationOptions}
                  disabled={isDisabled}
                  onFocus={inputsFocusing}
                  onBlur={inputsFocusing}
                />
              </CodeFormItem>
              <CodeFormItem
                $noMargin
                label={<ItemLabel label="Ticket Types*" title={FormTips.guestTicket} />}
                name={[MessageCreateFields.guestsHavingTickets]}
                rules={[{required: true, message: 'Guests having tickets is required'}]}>
                <StyledSelect
                  placeholder={'Choose Ticket Type'}
                  onChange={onGuestsHavingTicketsChange}
                  options={havingTicketsOptions}
                  disabled={isDisabled}
                  onFocus={inputsFocusing}
                  onBlur={inputsFocusing}
                />
              </CodeFormItem>
              <TicketTypeTip onClick={toggleExclude}>Set ticket-types to exclude from notification</TicketTypeTip>
              {!!flags.isExclude && (
                <CodeFormItem
                  $noMargin
                  label={<ItemLabel label="Exclude Ticket Types*" title={FormTips.guestTicketExclude} />}
                  name={[MessageCreateFields.excludeGuestsHavingTickets]}
                  rules={[{required: true, message: 'Guests having tickets is required'}]}>
                  <SelectWithCheckbox
                    options={excludeOptions}
                    placeholder="Choose Ticket Type To Exclude"
                    disabled={!excludeOptions}
                  />
                </CodeFormItem>
              )}
              <CodeFormItem
                label={<ItemLabel label="Ticket Status*" title={FormTips.ticketStatus} />}
                name={[MessageCreateFields.orderStatus]}
                rules={[{required: true, message: 'Ticket Status is required'}]}>
                <StyledSelect
                  placeholder={'Choose type'}
                  options={ticketStatusOptions}
                  disabled={isDisabled}
                  onFocus={inputsFocusing}
                  onBlur={inputsFocusing}
                />
              </CodeFormItem>
              <DoubleSelectWrapper>
                <CodeFormItem
                  label={<ItemLabel label="Start Date*" title={FormTips.visitingDateFrom} />}
                  name={[MessageCreateFields.visitingFromDate]}
                  rules={[{required: true, message: 'Visiting from date is required'}]}>
                  <CustomDatePicker
                    onFocus={inputsFocusing}
                    onBlur={inputsFocusing}
                    disabled={isDisabled}
                    status={flags.dateWarning ? 'error' : undefined}
                  />
                </CodeFormItem>
                <CodeFormItem
                  label={<ItemLabel label="Start Time*" title={FormTips.visitingDateFromTime} />}
                  name={[MessageCreateFields.visitingFromTime]}
                  rules={[{required: true, message: 'Visiting from time is required'}]}>
                  <StyledTimePicker
                    onFocus={inputsFocusing}
                    onBlur={inputsFocusing}
                    use12Hours
                    format="h:mm a"
                    placeholder="Choose a time"
                    disabled={isDisabled}
                  />
                </CodeFormItem>
              </DoubleSelectWrapper>

              <DoubleSelectWrapper>
                <CodeFormItem
                  label={<ItemLabel label="End Date*" title={FormTips.visitingDateUntil} />}
                  name={[MessageCreateFields.visitingUntilDate]}
                  rules={[{required: true, message: 'Visiting until date is required'}]}>
                  <CustomDatePicker
                    onFocus={inputsFocusing}
                    onBlur={inputsFocusing}
                    disabled={isDisabled}
                    status={flags.dateWarning ? 'error' : undefined}
                    onChange={inputsFocusing}
                  />
                </CodeFormItem>
                <CodeFormItem
                  label={<ItemLabel label="End Time*" title={FormTips.visitingDateUntilTime} />}
                  name={[MessageCreateFields.visitingUntilTime]}
                  rules={[{required: true, message: 'Visiting until time is required'}]}>
                  <StyledTimePicker
                    onFocus={inputsFocusing}
                    onBlur={inputsFocusing}
                    use12Hours
                    format="h:mm a"
                    placeholder="Choose a time"
                    disabled={isDisabled}
                    onChange={inputsFocusing}
                  />
                </CodeFormItem>
              </DoubleSelectWrapper>
              <SettingsTitle>Audience Flags</SettingsTitle>
              <CodeFormItem $noMargin name={[MessageCreateFields.allowDateChange]} valuePropName="checked">
                <CustomCheckbox disabled={isDisabled}>
                  <CheckboxLabel label="Allow Date Change" title={FormTips.allowDateChange} />
                </CustomCheckbox>
              </CodeFormItem>
              <CodeFormItem $noMargin name={[MessageCreateFields.allowRefund]} valuePropName="checked">
                <CustomCheckbox disabled={isDisabled}>
                  <CheckboxLabel label="Allow Refund" title={FormTips.allowRefund} />
                </CustomCheckbox>
              </CodeFormItem>
              <CodeFormItem name={[MessageCreateFields.skipMessaging]} valuePropName="checked">
                <CustomCheckbox disabled={isDisabled}>
                  <CheckboxLabel label="Skip Message" title={'No SMS/Email are sent'} />
                </CustomCheckbox>
              </CodeFormItem>
              <CodeFormItem
                label={<ItemLabel label="Flag Reason*" />}
                rules={[{required: false}]}
                name={[MessageCreateFields.flagReason]}>
                <Input placeholder="Type the reason for this flag" disabled={isDisabled} />
              </CodeFormItem>
            </LeftSide>
            <RightSide>
              <SettingsTitle>Message</SettingsTitle>
              <CodeFormItem
                label={<ItemLabel label="Message Template*" title={FormTips.template} />}
                name={[MessageCreateFields.eventTemplateName]}
                rules={[{message: 'Message Template is required'}]}>
                <StyledSelect
                  placeholder={'Select Message Template'}
                  options={templateOptions}
                  onChange={onTemplateNameChange}
                  disabled={isDisabled}
                />
              </CodeFormItem>
              <CodeFormItem
                label={<ItemLabel label="Message Subject" title={FormTips.subject} />}
                name={[MessageCreateFields.subject]}
                initialValue={defaultSubject}>
                <Input placeholder={'Write the message subject line'} disabled={isDisabled || !flags.isEmailMode} />
              </CodeFormItem>
              <CodeFormItem
                label={<ItemLabel label="Message Body*" title={FormTips.body} />}
                name={[MessageCreateFields.message]}
                rules={[{message: 'Message Body is required', max: text.limit, type: 'string'}]}>
                {text.limit === 1000 && <StyledSpan key="note">SMS length must be 160 or fewer characters</StyledSpan>}
                <TextArea
                  value={text.content || ''}
                  rows={8}
                  id={MessageCreateFields.message}
                  maxLength={text.limit}
                  placeholder={'Describe message details'}
                  disabled={isDisabled}
                  onChange={inputsFocusing}
                />
                <TextLimitTip key="tip" len={text.content.length || 0} max={text.limit}>{`${text.content.length || 0}/${
                  text.limit
                }`}</TextLimitTip>
              </CodeFormItem>

              <SettingsTitle>Instructions on adding dynamic links:</SettingsTitle>
              <InstructionForVariables onSelect={onSelectDynamicTemplate} />
              <ErrorLine>{error && <ErrorText>{error}</ErrorText>}</ErrorLine>
              {success?.status === 'completed' ? (
                <Success mode={mode} textName="Message" />
              ) : (
                <FormButton
                  loading={loading}
                  htmlType="submit"
                  type={'primary'}
                  disabled={flags.isCreateDisabled || isDisabled}>
                  {button}
                </FormButton>
              )}
            </RightSide>
          </MessageFormWrapper>
        </CreateNewMessageCodeForm>
      </CreateFormWrapper>
    </PageWrapper>
  );
};

export const Success: React.FC<{mode: FormMode; textName: string}> = ({mode, textName}) => {
  return (
    <SuccessLine>
      {mode === 'create' ? (
        <SuccessText>{textName} successfully created</SuccessText>
      ) : (
        <SuccessText>{textName} successfully updated</SuccessText>
      )}
    </SuccessLine>
  );
};

const CreateFormWrapper = styled.div<{$hidden?: boolean}>`
  width: 100%;
  display: ${({$hidden}) => ($hidden ? 'none' : 'block')};
`;
