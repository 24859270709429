import React from 'react';
import {Form, Tag} from 'antd';
import styled from 'styled-components';
import {CodeFormItem} from '../../components/Template/Form/styles';
import {ItemLabel} from '../../ui-kit/Labels';
import Input from '../../ui-kit/Input';
import {Button, ButtonTypes} from '../../ui-kit/Button';
import {useTestMessage} from '../../hooks/preview';
import {Form as FormAntd} from 'antd';
import {CheckCircleOutlined} from '@ant-design/icons';
import {validatePhone} from '../../helpers/validations';
import {TestMessageFields} from '../../types/messages';

type PropsT = {
  onClose: () => void;
  brand?: string;
  subject?: string;
  message?: string;
  notificationMode?: string[];
};

export const SendMessageForm: React.FC<PropsT> = ({brand, subject, message, notificationMode, onClose}) => {
  const [form] = FormAntd.useForm();
  const {
    onSubmit,
    onCheckOrder,
    onOrderChange,
    orderResult,
    order,
    orderLoading,
    isDisabled,
    loading,
    success,
    values,
    onReset,
  } = useTestMessage({
    brand,
    subject,
    message,
    notificationMode,
  });
  if (success) return <Success {...values} onClose={onClose} onReset={onReset} />;

  return (
    <Wrapper>
      <Title>Test Account</Title>
      <Form layout={'vertical'} form={form} onFinish={onSubmit}>
        <CodeFormItem
          label={<ItemLabel label="Test Order*" title={'Enter order number, order ID, or Order URL'} />}
          rules={[{required: false, message: 'Order ID is required'}]}
          name={'orderId'}>
          <Input
            value={order}
            onChange={onOrderChange}
            placeholder="Enter a valid order number or order ID...."
            suffix={
              !order ? null : orderLoading ? (
                <div>loading...</div>
              ) : orderResult === 'success' ? (
                <ValidationTag $valid={true} icon={<CheckCircleOutlined />}>
                  Valid
                </ValidationTag>
              ) : (
                <CheckButton onClick={onCheckOrder}>Check</CheckButton>
              )
            }
          />
          {orderResult === 'failed' && <LinkError>Invalid Link, please try again</LinkError>}
        </CodeFormItem>

        {notificationMode?.includes('sms') && (
          <CodeFormItem
            label={
              <ItemLabel
                label="Test Phone Number*"
                title={'Enter phone number where you would like to send this test message.'}
              />
            }
            rules={[
              {required: true, message: 'Phone required'},
              {
                message: 'Phone Number is not valid',
                validator: (_, value) => {
                  if (validatePhone(value?.replaceAll(' ', '')) || !value) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject('Phone Number is not valid');
                  }
                },
              },
            ]}
            name={'phone'}>
            <Input name="phone" placeholder="Enter phone number..." />
          </CodeFormItem>
        )}

        {notificationMode?.includes('email') && (
          <CodeFormItem
            label={
              <ItemLabel label="Test Email*" title={'Enter email where you would like to send this test message.'} />
            }
            rules={[{required: true, type: 'email', message: 'Email is required'}]}
            name={'email'}>
            <Input
              // value={ticketUrl}
              placeholder="Enter email..."
            />
          </CodeFormItem>
        )}
        <Divider />
        <FormButton htmlType="submit" type={'primary'} disabled={isDisabled} loading={loading}>
          Send Test
        </FormButton>
      </Form>
    </Wrapper>
  );
};

const Success = ({
  phoneNumber,
  email,
  ticketureOrderId,
  onClose,
  onReset,
}: TestMessageFields & PropsT & {onReset?: () => void}) => {
  return (
    <SuccessWrapper>
      <b>A test message has been sent to: </b>
      {phoneNumber && <p>Phone: {phoneNumber}</p>}
      {email && <p>Email: {email}</p>}
      {ticketureOrderId && <p>Order Number: {ticketureOrderId}</p>}
      <Button variant={ButtonTypes.secondary} onClick={onClose}>
        Close
      </Button>
      <Button variant={ButtonTypes.secondary} onClick={onReset}>
        Test Again
      </Button>
    </SuccessWrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 12px;
  .ant-form-item {
    margin-bottom: 11px;
  }
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #e6e7e8;
  margin-bottom: 16px;
  margin-top: 16px;
`;

export const FormButton = styled(Button)`
  width: 100%;
  height: 43px;
  font-size: 16px;
  font-weight: ${({theme}) => theme.font.weight.mediumBold};
  &:disabled {
    opacity: 0.6 !important;
  }
`;

const Title = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 4px;
`;

const CheckButton = styled.span`
  background-color: ${({theme}) => theme.palette.common.black};
  color: #fff;

  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  display: block;
  cursor: pointer;
  padding: 2px 8px;
  border-radius: 6px;
`;

export const LinkError = styled.div`
  height: 22px;
  padding-top: 4px;
  font-size: 14px;
  color: ${({theme}) => theme.palette.common.redBrown};
`;

export const ValidationTag = styled(Tag)<{$valid?: boolean}>`
  background-color: ${({theme, $valid}) => ($valid ? theme.palette.common.darkGreen : theme.palette.common.red)};
  border-color: ${({theme, $valid}) => ($valid ? theme.palette.common.darkGreen : theme.palette.common.red)};
  color: ${({theme}) => theme.palette.common.white};
  border-radius: 4px;
  height: 25px;
  width: 70px;
  padding-top: 2px;
  padding-left: 10px;
`;

const SuccessWrapper = styled.div`
  margin-top: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p {
    margin: 0px;
  }

  button {
    width: 90%;
    height: 40px;
    margin-top: 14px;
  }
`;
